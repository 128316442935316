import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createBrand, getStates, getCitiesByState, getAdminsBrands } from 'actions/index';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import Icon from 'components/Icon';
import H2 from 'components/H2';
import Tabs from 'components/Tabs';
import DetailsForm from './DetailsForm';
import AccountsForm from './AccountsForm';
import LocationsForm from './LocationsForm';
import DataForm from './DataForm';

const Container = styled.div`
  margin: 30px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Column = styled.div`
  flex-direction: column;
  align-self: center;
`;

class CreateBrand extends Component {
  state = {
    activeTab: 'Details',
    brand: {
      accounts: [],
    },
  };

  componentDidMount() {
    const { fetchStates, fetchAdminsBrands } = this.props;

    fetchStates();
    fetchAdminsBrands();
  }

  switchTab = activeTab => {
    this.setState({ activeTab });
  };

  getCities = stateId => {
    const { fetchCitiesByState } = this.props;

    fetchCitiesByState(stateId);
  };

  saveData = (data, nextTab) => {
    const { brand, activeTab } = this.state;
    const brandUpdated = {
      ...brand,
      ...data,
    };
    if (activeTab === 'Data') {
      const { submitBrand } = this.props;
      submitBrand(brandUpdated);
    } else {
      this.setState({
        activeTab: nextTab,
        brand: brandUpdated,
      });
    }
  };

  back = backTab => {
    this.setState({
      activeTab: backTab,
    });
  };

  render() {
    const { states, cities, token, adminsBrands } = this.props;
    const {
      activeTab,
      brand: { accounts },
    } = this.state;
    const detailsInitialValues = {
      name: '',
      address_line: '',
      address_line_1: '',
      city_id: '',
      state_id: '',
      zip_code: '',
      contact_first_name: '',
      contact_last_name: '',
      contact_phone_number: '',
      contact_email: '',
      createNewUser: true,
      isPointOfContact: true,
      brand_admin_id: null,
    };

    const dataInitialValues = {
      out_of_compliance: null,
      near_compliance_1_end: null,
      in_compliance_end: null,
      near_compliance_2_end: null,
      out_of_compliance_2_end: null,
      good_end: null,
      fair_end: null,
      poor_end: null,
    };

    const tabs = [
      {
        title: 'Details',
      },
      {
        title: 'Accounts',
      },
      {
        title: 'Locations',
      },
      {
        title: 'Data',
      },
    ];

    const additionalOptions =
      adminsBrands.length > 0
        ? adminsBrands.map(admin => ({
            label: `${admin.first_name} ${admin.last_name}`,
            value: admin.id,
          }))
        : [];

    return (
      <Container>
        <Icon onClick={() => push('/brands')} name="chevron-left" color={Colors.blue} />
        <Row>
          <Column>
            <H2 color={Colors.black} style={{ paddingRight: 30 }}>
              Add new brand
            </H2>
          </Column>
          <Column>
            <Tabs tabs={tabs} active={activeTab} />
          </Column>
        </Row>
        {activeTab === 'Details' && (
          <DetailsForm
            initialValues={detailsInitialValues}
            adminsBrands={additionalOptions}
            isInitialValid={false}
            cities={cities}
            states={states}
            getCities={this.getCities}
            onSubmit={this.saveData}
          />
        )}
        {activeTab === 'Accounts' && (
          <AccountsForm token={token} back={() => this.back('Details')} onSubmit={this.saveData} />
        )}
        {activeTab === 'Locations' && (
          <LocationsForm
            token={token}
            accounts={accounts}
            back={() => this.back('Accounts')}
            onSubmit={this.saveData}
          />
        )}
        {activeTab === 'Data' && (
          <DataForm
            initialValues={dataInitialValues}
            isInitialValid={true}
            back={() => this.back('Locations')}
            onSubmit={this.saveData}
          />
        )}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    states: state.utils.states,
    cities: state.utils.cities,
    token: state.auth.token,
    adminsBrands: state.brands.adminsBrands,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitBrand: data => dispatch(createBrand(data)),
    fetchStates: () => dispatch(getStates()),
    fetchCitiesByState: stateId => dispatch(getCitiesByState(stateId)),
    fetchAdminsBrands: () => dispatch(getAdminsBrands()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateBrand);
